.card-center {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.card-header-alt {
  background-color: #d3d6db !important;
  font-size: 16px !important;
  font-weight: bold !important;
  display: flex !important;
}

.card-header-icon-button {
  padding-right: 0px !important;
  vertical-align: center;
}

.card-header-button {
  float: right !important;
}
.card-header-light {
  background-color: #ffffff !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.card-header-light-tc {
  background-color: #ffffff !important;
  border: 1px solid #025c71;
  font-size: 14px !important;
  text-decoration: underline !important;
}
.input-group-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.card-section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
}

.card-input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 0.25rem;
  color: #5c6873;
  background-color: #f0f3f5;
  border-color: #e4e7ea;
}

.card-wide {
  margin-right: -1px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
}

.card-row {
  margin-right: -1px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: middle;
}

//couldn't ever get this to quite work correctly, just wanted to make a card
//with a width that matched the CInputGroupRow, but no settings would suffice
//ultimately required wrapping the "CCard" sequence with a "CRow"
.card-row-group {
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap !important;
  word-wrap: break-word !important;
  align-items: stretch !important;
  width: 100% !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  // margin-right: -15px !important;
  // margin-left: -15px !important;
  // flex-direction: row !important;
}

.card-row-group-card-header {
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap !important;
  word-wrap: break-word !important;
  align-items: stretch !important;
  width: 100% !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  color: #5c6873 !important;
  background-color: #f0f3f5 !important;
  border-color: #e4e7ea;
  font-size: 14px !important;
  font-weight: normal !important;
  padding: 0.75rem 1.25rem !important;
}

.card-row-group-header-row-group {
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap !important;
  word-wrap: break-word !important;
  align-items: stretch !important;
  width: 100% !important;
}
.card-row-group-header-row-group-prepend {
  margin-right: -1px !important;
  white-space: nowrap;
  vertical-align: middle;
}
.card-row-group-header-row-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  margin-right: -1px !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 0.25rem;
  color: #5c6873;
  background-color: #f0f3f5;
  border-color: #e4e7ea;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.card-row-alt {
  align-items: center;
  margin-bottom: 0;
  border-color: #e4e7ea;
  background-color: #f0f3f5;
  border-color: #d8dbe0;
  color: #5c6873;
  word-wrap: break-word;
}

.card-row-group-header-row-group-prepend-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.card-row-group-header-row-group-prepend-end {
  border-top-right-radius: 0;
}

.card-row-group-header-row-group-prepend-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 0.25rem;
  color: #5c6873;
  background-color: #f0f3f5;
  border-color: #e4e7ea;
}

.subcard-group {
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}
.subcard-card {
  margin-left: -15px;
  margin-right: -15px !important;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.subcard-group-footer-icon {
  margin-left: -15px;
  box-sizing: border-box;
  width: 100%;
}
.subcard-group-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: -10px;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  color: #5c6873;
  background-color: #f0f3f5;
}
.subcard-header-text {
  font-size: 14px !important;
  font-weight: bold;
}
.subcard-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
  word-wrap: break-word;
  background-color: $coreui-group-gray !important;
}
.subcard-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.subcard-group-header-tag {
  background-color: #f0f3f5;
}

.tab-card-header {
  background-color: #fff !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-bottom: #fff;
}
