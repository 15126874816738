@import './colors.scss';
@import './sizing.scss';

.p-icon-auto {
  display: flex !important;
  // flex: 1 1 100%;
  height: 1.25rem;
  // font-size: 100%;
  // line-height: 100%;
}

.p-icon-auto-top {
  @extend .p-icon-auto;
  flex-direction: column-reverse;
  justify-content: flex-start;
  // width: 1.5vw;
  vertical-align: top;
}

.p-icon-flex {
  display: flex !important;
}

// @media all and (max-width: $md) {
//   .p-icon-auto-top {
//     width: 2.3vw;
//   }

//   .p-icon-auto {
//     width: 5vw;
//   }
// }

// @media all and (max-width: $sm) {
//   .p-icon-auto-top {
//     width: 2.5vw;
//   }
//   .p-icon-auto {
//     width: 6vw;
//   }
// }

// @media all and (max-width: $xs) {
//   .p-icon-auto-top {
//     width: 3vw;
//   }
//   .p-icon-auto {
//     width: 8vw;
//   }
// }

.p-icon {
  display: inline-block;
  text-align: center;
  color: inherit;
}

.p-icon-sm {
  @extend .p-icon;
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
}

.p-icon-md {
  @extend .p-icon;
  width: 1.05rem;
  height: 1.05rem;
  font-size: 1.05rem;
}

.p-icon-lg {
  @extend .p-icon;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.p-icon-xl {
  @extend .p-icon;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.p-icon-2xl {
  @extend .p-icon;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

.p-icon-3xl {
  @extend .p-icon;
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.p-icon-4xl {
  @extend .p-icon;
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
}

.p-icon-5xl {
  @extend .p-icon;
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
}

.p-icon-6xl {
  @extend .p-icon;
  width: 6rem;
  height: 6rem;
  font-size: 6rem;
}

.p-icon-7xl {
  @extend .p-icon;
  width: 7rem;
  height: 7rem;
  font-size: 7rem;
}

.p-icon-8xl {
  @extend .p-icon;
  width: 8rem;
  height: 8rem;
  font-size: 8rem;
}

.p-icon-9xl {
  @extend .p-icon;
  width: 9rem;
  height: 9rem;
  font-size: 9rem;
}