$filter-input-min-height: 38px;

$header-default-height-vpw: 8;

$logo-default-height-vpw: 3;
$header-default-height: 130px;
$logo-default-height: 50px;
$logo-scaled-height: calc(($logo-default-height/$header-default-height) * $header-default-height-vpw);
.p-sidebar-header-logo {
  display: flex;
  flex-direction: row;
  text-align: center;
  color: inherit;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-right: 2.5%;
  margin-left: 2.5%;
  border: 0px dashed #e72a2a;
}
.p-header-sizing {
  display: flex;
  flex: 0 1 $header-default-height;
}
.flex-end {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  flex-direction: row;
  display: inline-flex;
}
.div-flex-end {
  float: right;
  display: inline-flex;
}
.div-container-boxed {
  color: #025c71;
  border: 0px solid #025c71;
  margin-left: $div-container-margin;
  margin-right: $div-container-margin;
  display: block;
  justify-content: center;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
}
.p-end {
  justify-content: flex-end;
}
.breadcrumbs-shifted {
  margin-left: -2.25rem !important;
}
.float-right-alt {
  float: right !important;
  margin-left: 0.25rem !important;
  margin-right: 0.5rem !important;
}
.force-break-line {
  white-space: pre-wrap;
}

.p-row {
  width: 100%;
}
.modalBreakLine {
  white-space: pre-wrap;
}
.modal-buttons-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.p-row-center {
  width: 100%;
  justify-content: center;
  align-content: center;
  justify-items: center;
  text-align: center;
}
.sorter-left {
  position: relative !important;
}

.w600 {
  width: 600px !important;
  margin: 0 auto !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
.p025rem {
  padding: 0.25rem !important;
}
.mh100 {
  min-height: 100px;
}
.mb-10 {
  margin-bottom: 10px;
}
.pleft15 {
  padding-left: 15px;
}
.vertical-align-middle td {
  vertical-align: middle !important;
}
.mb05rem {
  margin-bottom: 0.5rem !important;
}
.min-h-500 {
  min-height: 500px !important;
}
.max-h-300 {
  max-height: 300px !important;
  overflow-y: auto;
}
.left-margin {
  margin-left: 20px;
}
.border-radius-right-only {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.col-border {
  border-right: 1px solid #025c71;
}
.top-border-only {
  border-top-color: #e1e5ec !important;
  border-top-width: 2px !important;
  border-top-style: solid !important;
}
.force-right {
  align-items: right !important;
}
.full-width {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
}
.force-center {
  align-items: center !important;
}
.td-center {
  text-align: center !important;
}
.td-row {
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}
.br-0 > * {
  border-radius: 0 !important;
}
.float-left-important {
  float: left !important;
  margin-left: 0.25rem !important;
  margin-right: 0.5rem !important;
}
.v-hidden {
  visibility: hidden !important;
}
.tm-1_5rem {
  margin-top: 1.5rem;
}
.p-inline {
  display: inline-flex !important;
  width: 100%;
}
.p-inline-half {
  display: inline-flex !important;
  width: 50%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.p-inline-half-a {
  display: inline-flex !important;
  width: 50%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.p-inline-half-b {
  display: inline-flex !important;
  width: 50%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}
