@import '../../../webapp-lib/pathspot-react/styles/scss/colors.scss';

$wcFailFg: rgb(202, 44, 39);
$wcFailBg: rgb(215, 135, 139);
$wcSuccessFg: rgb(24, 200, 53);
$wcSuccessBg: rgb(154, 237, 185);
$wcIncompleteFg: rgb(237, 142, 14);
$wcIncompleteBg: rgb(246, 224, 135);
$wcNotStartedFg: rgb(64, 73, 73);
$wcNotStartedBg: rgb(167, 174, 174);
.modal-top {
  z-index: 9999999;
}
.wellness-title {
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  color: $pathspot-primary;
  justify-content: center;
}
.wellness-message {
  display: inline-block;
  // vertical-align: middle;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  color: $pathspot-primary;
  justify-content: center;
  > a {
    text-decoration: underline;
  }
}
.wellness-charts {
  display: inline-flex;
  vertical-align: middle;
}
.wellness-charts-title {
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  width: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  color: $coreui-gray-text;
  justify-content: center;
}
.donut-graph-title {
  @extend .wellness-charts-title;
  width: 40%;
}
.bar-graph-title {
  @extend .wellness-charts-title;
  width: 60%;
}
.donut-graph {
  @extend .wellness-charts;
  width: 40%;
  margin-left: -4rem;
}
.bar-graph {
  @extend .wellness-charts;
  width: 60%;
  margin-left: 3rem;
}
.result-message {
  display: contents;
  margin-bottom: 6px;
  margin-left: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.result-message-title {
  margin-bottom: 6px;
  margin-left: 6px;
}
.result-message-title-text {
  margin-bottom: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
}
.result-message-box {
  margin-bottom: 3px;
  margin-left: 6px;
  padding: 6px;
  width: 50%;
  height: 10;
}
.result-message-form-control {
  width: 100%;
  height: 10rem !important;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: pre-wrap;
  // text-align: start;
  word-break: break-all;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  vertical-align: text-top;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: $coreuiInputText;
  background-color: #fff;
  border-color: $pathspot-primary;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
}
.pass-icon {
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
  font-size: large;
}
.fail-icon {
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
}
.qa-details {
  display: inline-flex;
}
.response-details-row {
  width: 100%;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16;
  color: $coreui-gray-text;
  margin-bottom: 1rem;
}
.response-details-left {
  // width: 50%;
  display: inherit;
  justify-content: inherit;
}
.response-details-right {
  // width: 50%;
  display: inherit;
  justify-content: inherit;
}
[data-icon]:before {
  font-family: icons;
  content: attr(data-icon);
}
.submission-table {
  display: inline-flex;
}
.qa-container {
  display: flex;
  height: 3rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
  margin-right: -5px;
  margin-bottom: 1rem;
  vertical-align: middle;
  padding: 0rem 0rem;
  justify-content: center;
}
.qa-fill-bar {
  height: 3rem;
  background-color: rgba(76, 236, 177, 0.811); //rgba(103, 226, 201, 0.911);
  border-radius: inherit;
  text-align: left;
  padding-top: 0;
  text-indent: 1rem;
  vertical-align: middle;
  margin-right: -8rem;
  line-height: 3rem;
  color: black;
  // overflow: hidden;
  white-space: nowrap;
  // text-overflow: ellipsis;
}

.qa-bar-label {
  line-height: 100%;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  color: $pathspot-white;
  // font-size: 16;
  //font-weight: bold;
  //text-align: left;
  vertical-align: middle;
  //line-height: inherit;
}
.completed-percentage {
  float: right;
  text-align: end;
  margin-right: auto;
  margin-top: 15px;
  padding-right: 1%;
  align-items: center;
  font-weight: 700;
}
.modal-body-table-header {
  width: '50px';
  height: '50px';
  color: 'black';
  display: 'inline';
  font-weight: 800;
  font-size: medium;
}
.modal-body-title-left {
  float: left;
  margin: 2px;
}
.modal-body-title-right {
  float: right;
  margin: 2px;
}

.no-data {
  margin: auto;
  vertical-align: middle;
  padding: 0rem;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}
