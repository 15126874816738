@import './colors.scss';

.switch-end {
  justify-content: right !important;
  text-align: end !important;
  padding-bottom: 0.25rem;
  align-items: end !important;
}

.button-col {
  justify-content: right !important;
  text-align: end !important;
  padding-bottom: 0.25rem;
  align-items: end !important;
}

.button-md-right {
  vertical-align: middle;
  height: auto;
  padding: 0.75rem 0.75rem;
  font-size: 0.875rem;
  display: inline-block;
}

.button-div {
  padding: 0.75rem 0.75rem;
}

.btn-clear {
  background-color: transparent;
  color: inherit;
  border-color: transparent; // $pathspot-primary;
  box-sizing: content-box;
  line-height: 100%;
  padding: 0 !important;
}

.btn-clear-first {
  background-color: transparent;
  color: inherit;
  border-color: transparent; // $pathspot-primary;
  box-sizing: content-box;
  line-height: 100%;
  padding: 0 !important;
  z-index: 9999;
}

.btn-clear-add-border {
  background-color: transparent;
  color: inherit;
  border-color: transparent; // $pathspot-primary;
  box-sizing: content-box;
  line-height: 100%;
  padding: 0 !important;
  border-color: --color;
  border-width: 1px;
  border-style: solid;
  border-radius: 25%;
}

.pdf-button {
  margin-right: 3rem;
  height: 75%;
  width: 10%;
  min-width: fit-content;
  vertical-align: middle;
  margin: 0.5rem;
}

.selector-narrow {
  color: #025c71;
  margin: 3px;
  max-width: 20%;
}

.switch-toggle-custom {
  position: relative !important;
  top: 4px !important;
  margin-right: 10px !important;
}

.switch-toggle-custom.top1 {
  position: relative !important;
  top: 1px !important;
  left: -10px !important;
  margin-right: 2px !important;
}

.switch-toggle-custom.report-table {
  position: relative !important;
  top: 3px !important;
  left: 0 !important;
  margin-right: 0 !important;
}

button {
  outline: none !important;
}

.filter-button {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  width: auto;
  margin-right: 1rem;
}

.filter-button-header {
  display: inline-flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.modal-filter-button {
  display: inline-flex !important;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  width: auto;
  margin-right: 1rem;
}

.input-group-prepend {
  white-space: nowrap;
  vertical-align: middle;
}

html:not([dir='rtl']) .input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

input:focus,
input:active {
  outline: 0 none !important;
}

input[type='checkbox']:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

html:not([dir='rtl']) .input-group>.input-group-prepend>.btn,
html:not([dir='rtl']) .input-group>.input-group-prepend>.input-group-text,
html:not([dir='rtl']) .input-group>.input-group-append:not(:last-child)>.btn,
html:not([dir='rtl']) .input-group>.input-group-append:not(:last-child)>.input-group-text,
html:not([dir='rtl']) .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
html:not([dir='rtl']) .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* The switch - the box around the slider */
$toggler-switch-width: 3.5rem;
$toggler-switch-height: 2rem;
$toggler-slider-size: 1.5rem;
$toggler-slider-margin: 0.25rem;
$toggler-border-radius: 1rem;

.p-card-header-toggler {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;

  // min-width: 50px;
  // font-size: 1rem;
  // background-color: $pathspot-primary;
  // // border-radius: 0.5rem;
  // // border: 0; // remove default button style
  &:focus,
  &.focus {
    outline: 0;
  }

  // Opinionated: add "hand" cursor to non-disabled .navbar-toggler elements
  // &:not(:disabled):not(.c-disabled) {
  //   cursor: pointer;
  // }
}

// .p-card-header-toggler-icon {
//   display: block;
//   height: 1rem;
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 100% 100%;
// }

.switch {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: $toggler-switch-width;
  height: $toggler-switch-height;
  margin: 0;
  background-color: $pathspot-secondary;
  border-radius: $toggler-border-radius;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: $pathspot-white;
  // border: 2px dotted #ef27b6;
  height: $toggler-slider-size;
  width: $toggler-slider-size;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-top: $toggler-slider-margin;
  margin-bottom: $toggler-slider-margin;
  border-radius: $toggler-border-radius;
}

input:checked+.slider {
  background-color: $pathspot-white;
  transform: translateX($toggler-slider-size);
  // transform: translateX(calc(#{$toggler-slider-size} - #{$toggler-slider-margin}));
}

input:not(checked)+.slider {
  background-color: $pathspot-white;
  margin-left: $toggler-slider-margin;
}

.slider-text {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  color: $pathspot-bg-white;
}

$text-translate: calc(#{$toggler-slider-margin}-#{$toggler-slider-size});

input:checked+.slider+.slider-text {
  transform: translateX(-1.7rem);
  text-align: left;
  margin-right: $toggler-slider-margin;

  // transform: translateX(calc(#{$toggler-slider-size} - #{$toggler-slider-margin}));
}

input:not(checked)+.slider+.slider-text {
  margin-left: $toggler-slider-margin;
}