.react-grid-header-cell-item {
  color: #3c4b64;
  background-color: inherit;
  padding-left: 2px;
  margin-left: 2px;
  text-align: left;
  font-size: 16px;
  vertical-align: middle;
  // align-content: center;
}
.react-grid-item {
  color: #636f83;
  background-color: inherit;
  padding: 0px;
  margin-left: 0px;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 1rem;
  vertical-align: middle;
  align-content: center;
}
.react-grid-item-fixed {
  color: #636f83;
  background-color: inherit;
  padding: 0px;
  margin-left: 0px;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 1rem;
  vertical-align: middle;
  align-content: center;
  border-bottom: 6px double $table-border-blue;
  margin-bottom: 6px;
}
.react-grid-header-item {
  // border: 1px solid #025c71;
  // border-radius: 0.25rem;
  border-bottom: 6px double $table-border-blue;
  margin-bottom: 6px;
  font-size: 1rem;
}
.react-grid-item.react-draggable.cssTransforms.react-resizable {
  border-radius: 20px !important;
  border: none !important;
  background-color: rgba(255, 0, 0, 0.3) !important;
  cursor: pointer;
  //height: 24px !important;
}

//mockup
.react-grid-item-alt.react-draggable.cssTransforms.react-resizable {
  border-radius: 0.5rem !important;
  border: 1px dotted #025c71 !important;
  background-color: $pathspot-white !important;
  box-sizing: border-box;
  white-space: pre-wrap;
  // margin-left: -15px;
  // margin-right: -15px;
  cursor: pointer;
  padding: 0;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100% !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  //height: 24px !important;
}

.react-grid-table {
  word-wrap: break-word !important;
  word-break: break-all !important;
  white-space: pre-wrap !important;
  table-layout: fixed;
  width: 100%;
  .react-grid-header-item {
    // border: 1px solid #025c71;
    // border-radius: 0.25rem;
    border-bottom: 6px double $table-border-blue;
    margin-bottom: 6px;
    font-size: 1rem;
  }
  .react-grid-item {
    color: #636f83;
    background-color: inherit;
    border-width: 100%;

    padding-right: 2px;
    margin-left: 2px;
    margin-top: 2px;
    border-radius: 0.25rem;
    text-align: left;
    font-size: 1rem;
    vertical-align: middle;
    align-content: top;
  }
}
tr .react-grid-table .react-grid-item {
  .react-grid-cell-item {
    vertical-align: top !important;
  }
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-layout div {
  display: flex;
}
.react-grid-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
}
.react-grid-cell-item {
  color: $coreuiInputText;
  background-color: inherit;
  padding-left: 0.5rem;
  margin-left: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  // align-content: center;
  .react-grid-table {
    .react-grid-header-item {
      // border: 1px solid #025c71;
      // border-radius 0.25rem;
      border-bottom: 0px double $table-border-blue;
      margin-bottom: 3px;
      padding-right: 2px;
      margin-right: 2px;
    }
  }
}
