@import './colors.scss';
@import './sizing.scss';

.my-sidebar {
  display: 0 !important;
}
.my-sidebar {
  // color: $pathspot-secondary;
  .c-sidebar-nav-item {
    :hover {
      background-color: #00a29b;
    }
  }
  .c-sidebar-nav-dropdown {
    :hover {
      background-color: #00a29b;
    }
  }
  background-color: $pathspot-primary;
  .c-sidebar-nav-dropdown.c-show {
    :hover {
      background: transparent;
    }

    .c-sidebar-nav-dropdown-items {
      .c-sidebar-nav-item {
        :hover {
          background-color: #00a29b;
        }
      }
    }
  }
  .c-sidebar-nav-dropdown {
    .c-show {
      :hover {
        background: transparent;
      }
    }
    .c-sidebar-nav-item {
      .c-sidebar-nav-link {
        padding-left: 75px;
      }
    }
  }
}
.coreui-margin {
  color: $pathspot-grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $pathspot-white;
}
.c-switch-label .c-switch-slider::before,
.c-switch-label .c-switch-slider::before {
  z-index: 0 !important;
}
.my-sidebar {
  display: 0 !important;
}
.my-sidebar {
  .c-sidebar-nav-dropdown {
    .c-sidebar-nav-item {
      .c-sidebar-nav-link {
        padding-left: 75px;
      }
    }
  }
}
.c-switch-label .c-switch-slider::before,
.c-switch-label .c-switch-slider::before {
  z-index: 0 !important;
}
.coreui-margin {
  color: $pathspot-grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
.c-sidebar-brand {
  min-height: 104px;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: $pathspot-white;
}
.c-header .c-subheader {
  border-bottom: 1px solid $pathspot-primary-05p;
}

.c-header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2rem;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
@media all and (max-width: $xxs) {
  .c-header-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-height: 2.5rem;
    margin-top: 0.5rem;
    margin-left: -0.75rem !important;

    transition: width 0.3s;
  }
  .c-header-nav {
    display: inline-flex;
    flex-direction: row;
    min-height: 1rem;
    font-size: 0.75rem;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    min-height: 1rem;
    margin-top: -0.75rem;
    transition: width 0.3s;
  }
  .d-md-down-none {
    display: inline-flex !important;
  }

  .c-header-toggler-icon {
    display: flex;
    height: 1.5rem;
    flex-shrink: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
  }
}
.card-header {
  background-color: #d3d6db !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
