.grid-layout-draggable {
  color: #636f83;
  background-color: #d22811 !important;
  padding: 0px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  align-content: center;
}
.p-responsive-grid-wrapper {
  display: flex !important;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // align-content: center;
  align-self: center;
  border: 0px dashed $toastify-success;
}
.p-responsive-grid-container {
  display: inline-flex !important;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  // align-content: center;
  align-self: center;
  border: 0px dashed $danger;
}
.p-container-div-as-columns {
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  // margin-right: auto;
  border: 0.1rem solid $pathspot-primary-05p;
  border-radius: 2rem !important;
  background-color: $pathspot-white !important;
}
.p-container-div-as-rows {
  display: flex !important;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
  border: 0.1rem solid $pathspot-primary-05p;
  border-radius: 2rem !important;
  background-color: $pathspot-white !important;
}
.container-title {
  display: inline-flex !important;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  width: 95%;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 1.5rem;
  font-weight: bolder;
  padding-left: 0.5rem;
  color: $pathspot-primary;
  border-bottom: 0.1vw solid $pathspot-primary-05p;
}

// @media all and (max-width: 1000px) {
//   .container-title {
//     font-size: 1.75vw;
//     padding-left: 0.75vw;
//     border-bottom: 0.1vw solid $pathspot-primary-05p;
//   }
// }
// @media all and (max-width: 850px) {
//   .container-title {
//     font-size: 2vw;
//     padding-left: 0.75vw;
//     border-bottom: 0.1vw solid $pathspot-primary-05p;
//   }
// }
// @media all and (max-width: 710px) {
//   .container-title {
//     font-size: 2.5vw;
//     padding-left: 0.75vw;
//     border-bottom: 0.1vw solid $pathspot-primary-05p;
//   }
// }
// @media all and (max-width: 610px) {
//   .container-title {
//     font-size: 3.5vw;
//     padding-left: 0.75vw;
//     border-bottom: 0.2vw solid $pathspot-primary-05p;
//   }
// }
// @return if($return-calc == true, calc(#{$value1} + #{$value2}), $value1 + unquote(' + ') + $value2);
