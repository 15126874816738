.compliance-item-card {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.scan-img-container {
  max-width: 400px;

  & img {
    width: 100%;
    height: auto;
  }
}
.worker-row-options {
  position: relative !important;
  left: 40px !important;
  top: -26px !important;
}
.worker-row-number {
  position: absolute !important;
  left: 25px;
  font-size: 80%;
  padding: 2px;
  margin-top: 6px;
}
.the-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}
.shift-bar-hour-display {
  font-size: 75%;
  line-height: 100%;
  position: relative;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-top: 0px;
  padding-bottom: 10%;
  padding-left: 5px;
}
.static-shift-bar-hour-display {
  font-size: 75%;
  line-height: 100%;
  position: relative;
  margin-bottom: 0;
  margin-top: 5px;
  vertical-align: middle;
  padding-left: 5px;
}
.permission-table-item {
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
  color: hsl(0, 0%, 20%);
  font-size: 80%;
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 3px;
}
.user-role-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.user-role-item {
  min-width: 350px;
}
.edit-goals-header {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.help-footer {
  font-size: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background-color: #f4f5f8;
  align-items: center;
  justify-content: left;
}
.reporting-tabs {
  border: 1px solid $pathspot-primary;
  border-top: 1px solid transparent;
  margin-top: -13px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
