.form-OR-text {
  color: #025c71;
  background-color: #ffffff;
  border: 0px solid #025c71;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}
.form-message-nm {
  color: #025c71;
  font-size: 14px;
}
.form-message-nm-u {
  color: #025c71;
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  vertical-align: middle;
}
.form-message-padded {
  color: #025c71;
  background-color: #ffffff;
  border: 0px solid #025c71;
  text-align: center;
  font-size: normal;
  text-align: center;
  margin-left: 70px;
  margin-right: 70px;
}
.form-message-padded-start {
  color: #025c71;
  text-align: center;
  font-size: normal;
  text-align: center;
  margin-right: 50px;
}
.form-message-boxed-left {
  box-sizing: border-box;
  min-width: 100%;
  color: #025c71;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: left;
  font-size: large;
}

.form-message-boxed-center {
  box-sizing: border-box;
  color: #025c71;
  padding: 10px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: center;
  font-size: normal;
}

.form-message-ital {
  color: #025c71;
  text-align: center;
  font-size: large;
  font-style: italic;
}
.form-message-ital-boxed {
  color: #025c71;
  padding: 3px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  color: #025c71;
  text-align: center;
  font-size: large;
  font-style: italic;
}
.form-superscript {
  color: #025c71;
  text-align: top;
  font-size: small;
  font-weight: 400;
}

.form-message {
  color: #025c71;
  text-align: center;
  font-size: large;
  text-align: center;
}

.form-message-left {
  color: #025c71;
  text-align: left;
  font-size: large;
}

.form-message-boxed {
  color: #025c71;
  padding: 10px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: center;
  font-size: large;
}
.or-tile {
  color: #025c71;
  padding: 10px;
  border: 1px solid #025c71;
  border-radius: 0.25rem;
  text-align: center;
}

.form-title {
  color: #025c71;
  padding: 10px;
  text-align: left;
  font-size: x-large;
  font-weight: 400;
}
.form-item-label {
  color: #025c71;
  padding: 0.25rem;
  text-align: left;
  font-size: large;
}
.form-control {
  color: hsl(0, 0%, 20%) !important;
}
