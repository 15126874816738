@import './p-icon.scss';
@import './colors.scss';
@import './sizing.scss';
@import './core-ui-customizations.scss';
@import './p-grid.scss';
@import './cards.scss';
@import './pathspot.scss';
@import './react-grid.scss';
@import './pickers.scss';
@import './print.scss';
@import './clickable.scss';
@import './variables.scss';
@import './charts.scss';
@import './text.scss';
@import './forms.scss';
@import './layout.scss';
@import './header-elements.scss';

iframe {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.sticky-top {
  margin-bottom: 1.5rem !important;
}

.sticky-top .card {
  margin-bottom: 0;
}

iframe {
  border: none;
}

.Toastify__toast.Toastify__toast--error {
  white-space: pre-wrap;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link {
  color: $pathspot-primary;
  background-color: #fff;
  border-color: $pathspot-primary $pathspot-primary $pathspot-primary;
  border-bottom: 1px solid transparent;
}

.nav-tabs .nav-link {
  border-top: 1px solid $pathspot-primary;
  border-left: 1px solid $pathspot-primary;
  border-right: 1px solid $pathspot-primary;
  border-bottom: 1px solid $pathspot-primary;
  color: $pathspot-gray;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs {
  border-bottom: 1px solid $pathspot-primary;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -5px;
  border-color: $pathspot-primary;
}

.List {
  border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.fraction {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
  font-size: 12px;
}

.fraction>span {
  display: block;
  padding: 0.1em;
}

.fraction span.fdn {
  border-top: thin solid black;
}

.fraction span.bar {
  display: none;
}

.filter-select__value-container {
  overflow-y: auto !important;
  max-height: 100px !important;
}

.div-container {
  color: #025c71;
  margin-left: $div-container-margin;
  margin-right: $div-container-margin;
  display: block;
  justify-content: center;
  text-align: center;
  align-items: center;
  box-sizing: content-box;
}

.div-labeled-row {
  color: #025c71;
  padding-bottom: 1rem;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

.div-label-text {
  color: #025c71;
  text-align: left;
  vertical-align: middle;
  font-size: large;
  display: inline-flex;
  float: left;
}

.comp-arrow {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.collapse-chevron {
  float: right;
  vertical-align: middle;
}

.badge-yes {
  color: #fff !important;
  background-color: #a4cf59 !important;
}

.badge-no {
  color: #fff !important;
  background-color: #a55095 !important;
}

.item-border {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border: 1px solid #5c6873;
  padding: 4px;
  margin: 4px;
}

.p-img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-flex;
  resize: horizontal;
  width: 75%;
  align-content: center;
  justify-items: center;
  justify-self: center;
  position: relative;
}

.p-token {
  word-wrap: break-word;
  overflow-anchor: break-word;
  line-break: strict;
  padding: 0.25rem;
  margin: 0.25rem;
  flex-wrap: wrap;
}

.token-header {
  color: $danger;
  font-size: larger;
  text-align: left;
  font-weight: bold;
}

.token-label-bar-text {
  color: #025c71;
  text-align: left;
  vertical-align: middle;
  font-size: x-large;
  display: inline-flex;
  float: left;
  margin-top: 1rem;
}

.token-label-col {
  width: 100%;
  vertical-align: middle;
}

.token-label-bar {
  color: #025c71;
  width: 100%;
  display: inline-block;
  text-align: left;
  box-sizing: border-box;
}

.token-list {
  color: #025c71;
  padding: 0.5rem;
  margin: 0.5rem;
  line-height: 100%;
  border: 0px solid #025c71;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 1.5rem;
  vertical-align: middle;
  font-weight: 400;
  width: 100%;
  display: inline-flex;
}

.spinner-border-ref {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-line {
  display: inline-flex;
  color: #025c71;
  width: 100%;
  padding: 2rem;
  font-size: 1rem;
  font-weight: bolder;
  text-align: center;
  justify-content: center;
  border: 0px solid #aa1e8c;
}

.spinner-message {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  justify-content: center;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  border: 0px solid #aa1e8c;
}

// .popover-body {
//   min-width: 25rem;
// }

.popover-header {
  width: 24.9rem;
}

.tippy-content {
  min-width: 25rem;
  max-width: 25rem;
  padding: 0;
}

.tippy-box {
  min-width: 25rem;
  max-width: 25rem;
  padding: 0;
}


// .c-sidebar-nav-link,
// .c-sidebar-nav-dropdown-toggle .sidebar-wrap {
//   display: flex;
//   flex: 1 1;
//   align-items: center;
//   padding: 0.8445rem 1rem;
//   text-decoration: none;
//   text-decoration: line-through;
//   color: #f34545;
//   font-size: smaller;
//   white-space: wrap !important;
//   transition: background 0.3s, color 0.3s;
// }

.c-sidebar .c-sidebar-nav-label {
  white-space: wrap !important;
}