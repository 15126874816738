.multi-level-chart-row {
  height: 95px;
  display: flex;
}

.multi-level-chart-column-label {
  text-align: center;
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 3px;
}

.multi-level-chart-column-chart {
  // flex-basis: calc(100% - 200px);
  flex-grow: 1;
}

.multi-level-chart-row canvas {
  width: 100% !important;
}
.multi-level-chart-row {
  height: 95px;
  display: flex;
}

.multi-level-chart-column-label {
  text-align: center;
  font-size: 11px;
  height: 95px;
  padding-right: 30px;
  flex-grow: 0;
  padding-top: 30px;
  min-width: 100px;
}

.multi-level-chart-column-chart {
  // flex-basis: calc(100% - 200px);
  flex-grow: 1;
}

.multi-level-chart-row canvas {
  width: 100% !important;
}
.min-height-chart {
  min-height: 500px;
}

.chart-container-size {
  min-height: 500px;
  width: 100%;
}

.chart-container-size-simple {
  max-height: 80px;
  // width: 100%;
}
