// Variable overrides
$primary: #025c71;
$success: #00a29b;
$warning: #a4cf59;
$info: #a55095;
$danger: #f34545;

//fix for the coreui template issue
.p-0offset-sm-6 {
  @extend .p-0;
  @extend .offset-sm-6;
}

.wrapper-card {
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 4rem;
  margin-top: 0rem;
}