@import '../../styles/scss/colors.scss';

.btn-complete {
  background-color: $secondary;
  color: $pathspot-white;
  border-color: inherit;
}
.btn-warning {
  background-color: $warning;
  color: $pathspot-white;
  border-color: inherit;
}

.btn-primary {
  background-color: $primary;
  color: $pathspot-white;
  border-color: inherit;
}

.btn-error {
  background-color: $error;
  color: $pathspot-white;
  border-color: inherit;
}

.btn-ready {
  background-color: $ready;
  color: $pathspot-white;
  border-color: inherit;
}

.btn-grayed {
  background-color: $pathspot-gray-light;
  color: $pathspot-white;
  border-color: inherit;
}

.btn-active {
  background-color: $active;
  color: $pathspot-white;
  border-color: inherit;
}

.btn-inactive {
  background-color: $inactive;
  color: $pathspot-white;
  border-color: inherit;
}
.btn-secondary {
  background-color: $pathspot-secondary;
  color: $pathspot-white;
  border-color: $pathspot-white;
}
