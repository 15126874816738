.rdt-input-style {
  color: $pathspot-lavender;
}
.rdt-form-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  // flex: 1 1 auto;
  // flex-grow: 1;
  // flex-shrink: 1;
  // flex-basis: auto;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: -1px;
  text-align: center;
  background-clip: padding-box;
  border: 0px solid;
  background-color: transparent !important;
  color: $primary;
  border-color: #e4e7ea;
  // text-rendering: auto;
  //border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.rdt-form-control {
  display: flex;
  // width: 1%;
  height: calc(1.5em + 0.75rem + 2px);
  // flex: 1 1 auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  /* padding: 0.375rem 0.75rem; */
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0px;
  background-clip: padding-box;
  border: 1px solid;
  color: #5c6873;
  background-color: #fff;
  border-color: #e4e7ea;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 40px;
  z-index: 99999 !important;
  background: $pathspot-lavender;
  box-shadow: 0 1px 3px rgba(23, 101, 64, 0.1);
  border: 0px solid #f9f9f9;
}
.date-bgd {
  background: $pathspot-bg-white !important;
}
// .div-container {
//   .div-labeled-row {
//     .div-label-text {
//       .div-flex-end {
//         .rdt .rdtOpen {
//           .rdtPicker {
//             display: none;
//             position: absolute;
//             min-width: 250px;
//             padding: 4px;
//             margin-top: 40px;
//             z-index: 99999 !important;
//             background: $pathspot-bg-white;
//             box-shadow: 0 1px 3px rgba(23, 101, 64, 0.1);
//             border: 0px solid #f9f9f9;
//           }
//         }
//       }
//     }
//   }
// }

.div-container {
  .div-labeled-row {
    div.rdtPicker {
      // display: none;
      // position: absolute;
      // min-width: 250px;
      // padding: 4px;
      // margin-top: 40px;
      // z-index: 99999 !important;
      background: $pathspot-bg-white;
      // box-shadow: 0 1px 3px rgba(23, 101, 64, 0.1);
      // border: 0px solid #f9f9f9;
      // .rdtOpen .rdtPicker {
      //   display: block;
      //   color: #8d1928;
      // }
    }
  }
}

.rdt .rdtOpen .rdtPicker {
  background: $pathspot-bg-white !important;
}
.rdtOpen .rdtPicker {
  display: block;
  color: #8d1928;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
.rdtPicker .rdtTimeToggle {
  text-align: center;
}
.rdtPicker table {
  width: 100%;
  margin: 0;
  vertical-align: bottom !important;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}
td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}
.rdtCounters {
  display: inline-block;
}
.rdtCounters > div {
  float: left;
}
.rdtCounter {
  height: 100px;
}
.rdtCounter {
  width: 40px;
}
.rdtCounterSeparator {
  line-height: 100px;
  color: $pathspot-bg-white;
  font-size: 1.6em;
}
.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  color: $pathspot-bg-white;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: $pathspot-lavender-50;
  color: $pathspot-blue;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
  color: $pathspot-bg-white;
}
.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}
.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
.rdtTime td {
  cursor: default;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
.DateRangePicker_picker {
  z-index: 100 !important;
}
.DateRangePicker_picker {
  z-index: 99999 !important;
}
.DateRangePickerInput {
  padding: 2px 0 !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
}

@media (max-width: 768px) {
  .DateInput {
    width: 45% !important;
  }

  .DateInput_input {
    text-align: center;
  }

  .DateRangePicker {
    width: 100% !important;
  }

  .DateRangePickerInput {
    width: 100% !important;
  }
}

@media (min-width: 769px) {
  .DateInput {
    min-width: 48% !important;
  }

  .DateInput_input {
    text-align: center;
  }

  .DateRangePicker {
    width: 100% !important;
  }

  .DateRangePickerInput {
    width: 100% !important;
  }
}
