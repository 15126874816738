// $card-header-color: #d3d6db;
@import './colors.scss';

$card-header-color: #f21c0d;

$p-card-header-toggler-bg: transparent !default;
$p-card-header-toggler-padding-y: 0.25rem !default;
$p-card-header-toggler-padding-x: 0.75rem !default;
$p-card-header-toggler-font-size: 2rem !default;
$p-card-header-toggler-border: 0 !default;
$p-card-header-toggler-border-radius: 0.25rem !default;
$p-card-header-height: 3rem;

.p-card-header-container {
  display: inline-flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center !important;
  width: 100%;
  height: 100%;
}

// .p-card-header-text {
//   font-size: 1.25qqsrem;
//   color: #3c4b64;
// }

// .p-card-header {
//   position: relative;
//   display: inline-flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   flex-shrink: 0;
//   min-height: $p-card-header-height;

//   &[class*='bg-'] {
//     border-color: rgba($pathspot-black, 0.1);
//   }

//   &.p-card-header-fixed {
//     position: fixed;
//     right: 0;
//     left: 0;
//   }

//   .c-subheader {
//     border-bottom: 0;
//   }
// }
