//Import variables
@import 'variables';

// CoreUI Styles
@import '~@coreui/coreui-pro/scss/coreui.scss';

// Import styles from pathspot lib
@import '~spinkit/spinkit.min.css';
@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
@import '../modules/forms/wellness-checks/wellness-checks.scss';
@import '../webapp-lib/pathspot-react/styles/scss/webappstyles.scss';
