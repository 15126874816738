.print-only-display {
  display: none;
  padding-bottom: 0;
}

// styles for the PDF export (print)
@media print {
  html,
  body:last-child {
    // border: 1px solid white;
    // height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .print-hide {
    display: none !important;
  }

  .print-only-display {
    display: block !important;
    padding-bottom: 30px;
  }

  .print-no-break {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    page-break-inside: avoid !important;
    break-inside: avoid;
    padding-top: 30px;
  }

  .print-break-before {
    page-break-before: always !important;
    page-break-after: always !important;
    break-before: always;
    break-after: always;
  }

  canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    // max-height: 4in;
    height: auto !important;
    width: auto !important;
  }

  .print-max-height {
    max-height: 2in;
  }

  .min-height-chart {
    min-height: 0;
  }
}
// @media print {
//   body * {
//     visibility: hidden;
//   }
//   #printSection,
//   #printSection * {
//     visibility: visible;
//     // transform: scale(1.1);
//   }
//   #printSection {
//     width: 100%;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }
