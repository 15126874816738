.header-background-boxed-normal {
  color: #636f83;
  background-color: #f0f3f5 !important;
  padding: 4px;
  margin: 0px;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
}
.header-font {
  color: #636f83;
  font-size: 14px;
  text-align: left;
  font-weight: lighter;
}
.header-background-boxed {
  color: $pathspot-gray !important;
  background-color: $pathspot-white !important;
  padding: 10px;
  margin: 0px;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  align-content: center;
}
.header-background-gray {
  color: #636f83;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin: 0px;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  text-align: left;
  vertical-align: middle;
}
.header-error {
  color: #d60b0b;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin: 0px;
  border: 0px solid #e4e7ea;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0.25rem;
  text-align: left;
  vertical-align: middle;
}
.error-text {
  color: #d60b0b;
  background-color: #f0f3f5 !important;
  padding: 0px;
  margin: 0px;
  border: 0px solid #d60b0b;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}
.column-text-centered {
  color: #025c71;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.column-text-centered-lg {
  color: #025c71;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.column-text-centered-xl {
  color: $pathspot-blue-150;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  margin: 2rem;
}

.primary-font-italic {
  font-size: medium;
  font-style: italic;
  font-weight: bold;
  color: #025c71;
}
.primary-font-italic-end {
  display: flex;
  font-size: 11pt;
  font-style: italic;
  font-weight: bold;
  text-align: end;
  justify-content: flex-end;
  margin-right: 6px;
}
.tooltip-actual-header {
  text-transform: uppercase;
  background-color: 'darkgrey';
  padding: 10px;
}

.custom-invalid-field-feedback {
  display: inline-flex;
  justify-content: flex-end;
  justify-self: right;
  margin-top: -0.5rem;
  font-size: 1rem;
  text-align: end;
  color: #e55353;
}
.text-left {
  text-align: left;
}
